import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { FilterData } from "@/core/types/misc/FilterData";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { ListCategories } from "@/core/types/gws-master/master/medical-services/product/ListCategories";
import {
  ListItem,
  QuotaItem,
} from "@/core/types/gws-master/quota-config/ListItem";
import { FormData } from "@/core/types/gws-master/quota-config/FormData";
import { OrgType } from "@/store/enums/OrgTypeEnums";
import { OrgItem } from "@/core/types/gws-master/quota-config/OrgItem";
import { PickedItem } from "@/core/types/gws-master/quota-config/PickedItem";
import { formatInternationalDate } from "@/core/helpers/date-format";
import { PickedEditItem } from "@/core/types/gws-master/quota-config/PickedEditItem";

const orgType = [
  {
    name: "Syarikah",
    value: "SYARIKAH",
  },
  {
    name: "P3MI",
    value: "P3MI",
  },
  {
    name: "Bursa Kerja",
    value: "JOB_FAIR",
  },
  {
    name: "Fasilitas Kesehatan",
    value: "MEDICAL",
  },
  {
    name: "Balai Latihan Kerja",
    value: "BLK",
  },
  {
    name: "Lembaga Sertifikasi Profesi",
    value: "LSP",
  },
];
export const useQuotaStore = defineStore({
  id: "quotaStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateOrgLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      stateFilterOrgData: {
        page: 1,
        limit: 999,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      stateCategory: [] as ListCategories[],
      statePagination: new PaginationType(),
      stateOrgPagination: new PaginationType(),
      stateDisplayData: [] as QuotaItem[],
      stateOrgData: {} as OrgItem,
      stateActiveOrg: "",
      stateAllOrgType: orgType,
      stateFormPlanningData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      statePickedData: [] as PickedItem[],
      statePickedEditData: [] as PickedEditItem[],
      stateUpdateId: "",
      stateErrorMsg: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    orgLoading(state) {
      return state.stateOrgLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    orgData(state) {
      return state.stateOrgData;
    },
    categories(state) {
      return state.stateCategory;
    },
    activeOrgId(state) {
      return state.stateActiveOrg;
    },
    allOrganization(state) {
      return state.stateAllOrgType;
    },
    formPlanningData(state) {
      return state.stateFormPlanningData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
    pickedData(state) {
      return state.statePickedData;
    },
    pickedEditData(state) {
      return state.statePickedEditData;
    },
    endPoint(state) {
      switch (state.stateActiveOrg) {
        case OrgType.SYARIKAH:
          return "syarikah";
          break;
        case OrgType.P3MI:
          return "companies";
          break;
        case OrgType.JOB_FAIR:
          return "job-fairs";
          break;
        case OrgType.MEDICAL:
          return "medical/facilities";
          break;
        case OrgType.BLK:
          return "blk";
          break;
        case OrgType.LSP:
          return "lsp";
          break;
        default:
          return "";
          break;
      }
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      this.stateIsLoading = true;
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
        organization_type: this.stateActiveOrg || "",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `quotas?${new URLSearchParams(params).toString()}`
          );
        this.stateDisplayData = response.data.data.quota;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getOrgData() {
      this.stateOrgLoading = true;
      const params = {
        page: this.stateFilterOrgData.page.toString() || "",
        limit: this.stateFilterOrgData.limit.toString() || "",
        dir: this.stateFilterOrgData.dir || "",
        sort: this.stateFilterOrgData.sort || "",
      };
      if (this.stateFilterOrgData.search) {
        params["search"] = this.stateFilterOrgData.search;
      }
      try {
        const response: AxiosResponse<ApiResponse<OrgItem>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `${this.endPoint}?${new URLSearchParams(params).toString()}`
          );
        this.stateOrgData = response.data.data;
        this.setOrgTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateOrgLoading = false;
    },
    async insertData() {
      this.stateIsLoading = true;
      try {
        const quota = [] as number[];
        this.statePickedData.forEach((el) => {
          quota.push(el.quota);
        });
        const totalQuota = quota.reduce((a, b) => {
          return parseInt(a.toString()) + parseInt(b.toString());
        });
        const payload = {
          organization_type: this.stateActiveOrg,
          start_date: formatInternationalDate(
            this.stateFormPlanningData.start_date
          ),
          end_date: formatInternationalDate(
            this.stateFormPlanningData.end_date
          ),
          total_quota: totalQuota,
          total_organization: this.statePickedData.length,
          detail: this.statePickedData.map((el) => {
            return {
              organization_id: el.id,
              quota: el.quota as number,
            };
          }),
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/quotas`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const quota = [] as number[];
        this.statePickedEditData.forEach((el) => {
          quota.push(el.quota);
        });
        const totalQuota = quota.reduce((a, b) => {
          return parseInt(a.toString()) + parseInt(b.toString());
        });
        const payload = {
          organization_type: this.stateActiveOrg,
          start_date: formatInternationalDate(
            this.stateFormPlanningData.start_date
          ),
          end_date: formatInternationalDate(
            this.stateFormPlanningData.end_date
          ),
          total_quota: totalQuota,
          total_organization: this.statePickedEditData.length,
          detail: this.statePickedEditData.map((el) => {
            return {
              organization_id: el.id,
              quota: el.quota as number,
            };
          }),
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/quotas/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/quotas/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async activateQuota(item: QuotaItem) {
      this.stateIsLoading = true;
      try {
        const payload = {
          organization_type: item.organization_type,
          status: "RUNNING",
        };
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/quotas/${item.id}`,
            payload as AxiosRequestConfig
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
    async setOrgPage(newPage: number) {
      this.stateOrgPagination.handleCurrentPageChange(newPage);
      this.stateOrgPagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterOrgData.page = this.stateOrgPagination.currentPage;
      await this.getOrgData();
    },
    async setOrgPageSize(size: number) {
      this.stateFilterOrgData.limit = size;
      this.stateOrgPagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterOrgData.page = this.stateOrgPagination.currentPage;
      await this.getOrgData();
    },
    setOrgTotalPage(totalPage: number) {
      this.stateOrgPagination.totalPage = totalPage;
      this.stateOrgPagination.getPaginationStartIndex();
      this.stateOrgPagination.getPaginationLastIndex();
    },
    setFormData(data: FormData) {
      this.stateFormPlanningData = data;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
