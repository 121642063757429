enum OrgType {
  SUPER_ADMIN = "SUPER_ADMIN",
  SYARIKAH = "SYARIKAH",
  P3MI = "P3MI",
  AGENT_P3MI = "AGENT_P3MI",
  MEDICAL = "MEDICAL",
  JOB_FAIR = "JOB_FAIR",
  LSP = "LSP",
  BLK = "BLK",
}

export { OrgType };
