import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6267276e")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "d-flex gap-2 justify-content-center"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "d-flex gap-2 justify-content-center" }
const _hoisted_5 = { class: "d-flex gap-2 justify-content-center" }
const _hoisted_6 = ["onClick"]
_popScopeId()


import { OrgType } from "@/store/enums/OrgTypeEnums";
import { useQuotaStore } from "@/store/pinia/gws-master/quota-config/useQuotaStore";
import { PickedItem } from "@/core/types/gws-master/quota-config/PickedItem";
import { checkButtonPermission } from "@/core/helpers/helpers-utils";
//props

export default _defineComponent({
  props: {
  listData: {
    required: true,
    type: Array,
  },
  isAuto: {
    required: false,
    type: Boolean,
    default: false,
  },
  isDetail: {
    required: false,
    type: Boolean,
    default: false,
  },
  isEdit: {
    required: false,
    type: Boolean,
    default: false,
  },
},
  emits: ["remove-org"],
  setup(__props, { emit: emits }) {

const props = __props

const quotaStore = useQuotaStore();


const handleRemove = (item: PickedItem) => {
  emits("remove-org", item);
};

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_unref(quotaStore).orgLoading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, null, 512)), [
        [_directive_loading, _unref(quotaStore).orgLoading]
      ])
    : _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 1,
        data: props.listData,
        "max-height": 400,
        "empty-text": 'Belum ada data dipilih.',
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "Nama",
            "min-width": "100"
          }),
          (!__props.isEdit)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                label: "Kuota",
                width: "100",
                align: "center"
              }, {
                default: _withCtx((scope) => [
                  (!__props.isDetail)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_el_form_item, { class: "m-0" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: scope.row.quota,
                              "onUpdate:modelValue": ($event: any) => (scope.row.quota = $event),
                              disabled: __props.isAuto || __props.isEdit
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(scope.row.quota), 1))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (__props.isEdit)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 1,
                label: "Kuota Awal",
                width: "100",
                align: "center"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_form_item, { class: "m-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: scope.row.init_quota,
                          "onUpdate:modelValue": ($event: any) => (scope.row.init_quota = $event),
                          disabled: __props.isEdit
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (__props.isEdit)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 2,
                label: "Tambahan",
                width: "100",
                align: "center"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_el_form_item, { class: "m-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: scope.row.quota,
                          "onUpdate:modelValue": ($event: any) => (scope.row.quota = $event),
                          disabled: __props.isAuto
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_table_column, {
            prop: "name",
            label: "",
            "min-width": "30",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              (!__props.isDetail && _unref(checkButtonPermission)('D'))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn-delete",
                    fixed: "right",
                    onClick: ($event: any) => (handleRemove(scope.row))
                  }, [
                    _createVNode(_component_inline_svg, { src: `media/gws/trash.svg` }, null, 8, ["src"])
                  ], 8, _hoisted_6))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "empty-text"])), [
        [_directive_loading, _unref(quotaStore).orgLoading]
      ])
}
}

})